

.burger { 
    input + label {
      position: fixed;
      top: 20px;
      height: 19px;
      width: 25px; 
      z-index: 5;
      span {
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 10px;
        top: 50%;
        margin-top: -1px;
        left: 0;
        display: block;
        background: #0090BC;
        transition: .5s;
      }
      span:first-child {
        top: 3px; 
      }
      span:last-child {
        top: 16px; 
      }      
    }
    label:hover {
      cursor: pointer;
    }
    input:checked + label { 
      span {
        background-color: white;
        opacity: 0;
        top: 50%;
      }
      span:first-child {
        opacity: 1;
        transform: rotate(405deg);
      }
      span:last-child {
        opacity: 1;
        transform: rotate(-405deg);
      }
    }
    input ~ nav {
      background-color: white;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      z-index: 3;
      transition: .5s;
      transition-delay: .5s;
      overflow: hidden;
      > ul {
        text-align: center;
        position: absolute;
        top: 15%;
        left: 20%;
        right: 20%;
        > li {
          opacity: 0;
          transition: .5s;
          transition-delay: 0s;
          > a {
            text-decoration: none;
            color: white;
            font-weight: 300;
            font-size: 20px;
            display: block;
            padding: 30px;
          }
        }
      }
    }
    input:checked ~ nav { 
      background-color: #0090BC;
      height: 100%;
      transition-delay: 0s;
      > ul {
        > li {
          opacity: 1;
          transition-delay: .5s;
        }
      }
    } 
}

.title {
color : #0090BC;
}

